import { prop, Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import jsonPathService from '@/services/json-path-service'
import { Config } from '@/config'
import { Subscription } from 'rxjs'

// Define props in a class
class Props {
  container = prop({
    type: Object,
    required: true,
    default: {}
  });
}

export default class TableContainer extends Vue.with(Props) {
  dataToDisplay: any = [];
  canShowTable = false;
  minUrlLength = 4;
  canShowDownload: boolean = false;
  showMoreOption: boolean = true;
  maxRowCount = Config.TABLE_MAX_ROW_LENGTH;
  canShowMoreOption: boolean = false;
  dataRefreshSubscription: Subscription | null = null;

  mounted () {
    this.init()
    this.clearDataRefreshSubscription()
    this.dataRefreshSubscription = apiService.detailsDataRefreshSubject.subscribe(() => {
      this.init()
      this.retryDownload()
    })
  }

  unmounted (): void {
    this.clearDataRefreshSubscription()
  }

  init () {
    if (this.container.source) {
      this.canShowTable = true
      this.dataToDisplay = this.getFieldByKey(
        apiService.containerData,
        this.container.source,
        false
      )
    } else {
      this.dataToDisplay = apiService.containerData
    }
    if (!this.dataToDisplay) {
      this.dataToDisplay = []
    } else if (
      !Array.isArray(this.dataToDisplay) &&
      typeof this.dataToDisplay === 'object'
    ) {
      const result = this.dataToDisplay
      this.dataToDisplay = []
      this.dataToDisplay.push(result)
    }
    this.canShowDownload = this.hasDownloadUrlInAnyRows(this.dataToDisplay)
    this.canShowMoreOption = this.dataToDisplay.length > this.maxRowCount
  }

  getFieldByKey (source: any, accessKey: string, sanitize = true) {
    const result = jsonPathService.getValueByField(source, accessKey)
    if (sanitize) {
      return this.$sanitize(result)
    } else {
      return result
    }
  }

  getCellValue (source: any, accessKey: string) {
    const value = this.getFieldByKey(source, accessKey, false)
    if (Array.isArray(value)) {
      const result = value.toString()
      return result.length > 0 ? result : 'N/A'
    } else if (typeof value === 'number') {
      return value
    } else {
      return value || 'N/A'
    }
  }

  getColumnCount () {
    return this.container.columns.length + 1
  }

  hasDownloadUrlInAnyRows (rows: any[]) {
    if (Array.isArray(rows)) {
      const result = rows.filter(
        row => row.active_download_url?.length > this.minUrlLength
      )
      return result?.length > 0
    } else {
      return false
    }
  }

  async downloadFile (row: any) {
    row.validatingUrl = true
    const isValid = await apiService.isDownloadUrlValid(row.active_download_url)
    row.validatingUrl = false
    if (isValid) {
      this.resetRetry()
      const anchorElement = document.createElement('a')
      anchorElement.href = row.active_download_url
      anchorElement.download = row.filename
      anchorElement.click()
    } else {
      this.initRetry(row)
    }
  }

  resetRetry () {
    apiService.downloadRetry.retryCount = 0
    apiService.downloadRetry.retryDocumentId = null
  }

  initRetry (row: any) {
    if (apiService.downloadRetry.retryCount < apiService.MAX_DOWNLOAD_RETRY_COUNT) {
      apiService.downloadRetry.retryCount++
      apiService.downloadRetry.retryDocumentId = row.documentId
      this.$emit('onTableDataRefreshRequested')
    } else {
      this.resetRetry()
    }
  }

  retryDownload () {
    if (!apiService.downloadRetry.retryDocumentId) {
      return
    }
    const resultRow = this.dataToDisplay.find(
      (document: any) => document.documentId === apiService.downloadRetry.retryDocumentId
    )
    if (resultRow) {
      this.downloadFile(resultRow)
    }
  }

  clearDataRefreshSubscription () {
    if (this.dataRefreshSubscription && this.dataRefreshSubscription?.unsubscribe) {
      this.dataRefreshSubscription.unsubscribe()
    }
  }

  toggleShowMore () {
    this.showMoreOption = !this.showMoreOption
  }
}
